import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ProfileModule } from './profile/profile.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './dashboard/home/home.component';

//import { PatientComponent } from './dashboard/patient/patient.component';
//import { OverviewComponent } from './dashboard/patient/overview/overview.component';
// import { ActionsComponent } from './dashboard/patient/actions/actions.component';
import { ScheduleComponent } from './dashboard/schedule/schedule.component';
import { PatientsListComponent } from './dashboard/patients-list/patients-list.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule }from'@angular/forms';
import { ReceptionistHomeComponent } from './dashboard/receptionist-home/receptionist-home.component';
import * as bootstrap from 'bootstrap';
import * as $ from 'jquery';
import * as moment from 'moment';
import { JwPaginationComponent } from 'jw-angular-pagination';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';



//import { PersonalDetailsComponent } from './dashboard/patient/personal-details/personal-details.component';
import { EnquiryComponent } from './dashboard/enquiry/enquiry.component';
import { BillingComponent } from './dashboard/billing/billing.component';
import { NotificationComponent } from './dashboard/notification/notification.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
//import { EventsComponent } from './dashboard/events/events.component';
//import { SymptomsDiagnosisComponent } from './dashboard/patient/symptoms-diagnosis/symptoms-diagnosis.component';
//import { IndividualScheduleComponent } from './dashboard/schedule-individual/schedule-individual.component';
import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { ImageViewerModule } from "ngx-image-viewer";
import { ViewerComponent } from './viewer/viewer.component';
//import { StatisticsComponent } from './dashboard/statistics/statistics.component';
//import { StatisticsOverviewComponent } from './dashboard/statistics/overview/overview.component';
//import { StatisticsDemographyComponent } from './dashboard/statistics/demography/demography.component';
//import { StatisticsRevenueComponent } from './dashboard/statistics/revenue/revenue.component';
//import { StatisticsDisabilityComponent } from './dashboard/statistics/disability/disability.component';
//import { UsersComponent } from './dashboard/users/users.component';
//import { ServiceComponent } from './dashboard/service/service.component';
//import { InventoryComponent } from './dashboard/inventory/inventory.component';

//import { PurchaseOrdersComponent } from './dashboard/purchase-orders/purchase-orders.component';
//import { SalesComponent } from './dashboard/sales/sales.component';
//import { TransferLogComponent } from './dashboard/transfer-log/transfer-log.component';
//import { AssetsComponent } from './dashboard/assets/assets.component';
//import { HometherapyGoalsComponent } from './dashboard/hometherapy-goals/hometherapy-goals.component';
//import { HometherapyComponent } from './dashboard/patient/hometherapy/hometherapy.component';
//import { PurchaseOrderComponent } from './dashboard/purchase-order/purchase-order.component';
//import { PurchaseHistoryComponent } from './dashboard/purchase-history/purchase-history.component';
import { ReportIssueComponent } from './dashboard/report-issue/report-issue.component';
//import { PatientFeedbackComponent } from './patient-feedback/patient-feedback.component';
//import { FeedbacksComponent } from './dashboard/feedbacks/feedbacks.component';
//import { FormBuilderComponent } from './dashboard/form-builder/form-builder.component';
//import { FormSubmissionsComponent } from './dashboard/form-submissions/form-submissions.component';
//import { FormSubmissionComponent } from './dashboard/form-submission/form-submission.component';
//import { ChildHistoryComponent } from './dashboard/patient/child-history/child-history.component';
//import { IndexComponent } from './dashboard/patient/child-history/index/index.component';
//import { MedicalFamilyHistoryComponent } from './dashboard/patient/child-history/medical-family-history/medical-family-history.component';
//import { LanguageComponent } from './dashboard/patient/child-history/language/language.component';
//import { SocioFamilyComponent } from './dashboard/patient/child-history/socio-family/socio-family.component';
//import { ChildRegistrationComponent } from './dashboard/patient/child-history/child-registration/child-registration.component';
//import { ChildInformationComponent } from './dashboard/patient/child-history/child-information/child-information.component';
//import { FamilyHistoryComponent } from './dashboard/patient/child-history/family-history/family-history.component';
//import { BirthHistoryComponent } from './dashboard/patient/child-history/birth-history/birth-history.component';
//import { MedicalHistoryComponent } from './dashboard/patient/child-history/medical-history/medical-history.component';
//import { BehaviouralHistoryComponent } from './dashboard/patient/child-history/behavioural-history/behavioural-history.component';
//import { EduHistoryComponent } from './dashboard/patient/child-history/edu-history/edu-history.component';
//import { ChildDevInventoryComponent } from './dashboard/patient/child-dev-inventory/child-dev-inventory.component';
import { ResourceComponent } from './dashboard/resource/resource.component';
//import { HomeprogramComponent } from './dashboard/patient/homeprogram/homeprogram.component';
import { ChatWindowComponent } from './videochat/chat-window/chat-window.component';
import { PublisherComponent } from './videochat/publisher/publisher.component';
import { SubscriberComponent } from './videochat/subscriber/subscriber.component';
//import { ResourceLibraryComponent } from './dashboard/resource-library/resource-library.component';
//import { HomeprogramInternalComponent } from './dashboard/patient/homeprogram-internal/homeprogram-internal.component';
//import { SignupComponent } from './signup/signup.component';
//import { SuperAdminComponent } from './dashboard/super-admin/super-admin.component';
import { BillComponent } from './dashboard/bill/bill.component';
//import { RecentActivitiesComponent } from './dashboard/recent-activities/recent-activities.component';
import { PatientsBranchComponent } from './dashboard/patients-list/patients-branch/patients-branch.component';
import { PatientsReferredComponent } from './dashboard/patients-list/patients-referred/patients-referred.component';
//import { TbcfComponent } from './dashboard/patient/tbcf/tbcf.component';
//import { InfancyComponent } from './dashboard/patient/child-history/infancy/infancy.component';
//import { AdditionalInfoComponent } from './dashboard/patient/child-history/additional-info/additional-info.component';
//import { AnnexeComponent } from './dashboard/patient/child-history/annexe/annexe.component';
//import { CdiResultComponent } from './dashboard/patient/cdi-result/cdi-result.component';
//import { TagsComponent } from './dashboard/tags/tags.component';
//import { FormComponent } from './dashboard/patient/form/form.component';
//import { AppointmentsComponent } from './dashboard/patient/appointments/appointments.component';
import { AnalyticsComponent } from './dashboard/analytics/analytics.component';
//import { SettingsComponent } from './dashboard/settings/settings.component';
//import { IdeComponent } from './dashboard/patient/ide/ide.component';
//import { GoalsActivitiesComponent } from './dashboard/patient/goals-activities/goals-activities.component';
//import { SurveyComponent } from './dashboard/patient/survey/survey.component';
//import { ChecklistComponent } from './dashboard/patient/checklist/checklist.component';
//import { MrRecordComponent } from './dashboard/patient/mr-record/mr-record.component';
import { ScheduleFlexComponent } from './dashboard/schedule-flex/schedule-flex.component';
import { GenerateBillComponent } from './dashboard/generate-bill/generate-bill.component';
//import { LessonPlanComponent } from './dashboard/patient/lesson-plan/lesson-plan.component';
//import { SessionReportComponent } from './dashboard/patient/session-report/session-report.component';
//import { BillHeadsComponent } from './dashboard/bill-heads/bill-heads.component';
//import { AssessmentsComponent } from './dashboard/patient/assessments/assessments.component';
import { PatientProfileComponent } from './dashboard/patient-profile/patient-profile.component';
import { ProfileboxComponent } from './dashboard/patient-profile/profilebox/profilebox.component';
import { HeaderComponent } from './dashboard/patient-profile/header/header.component';
import { GoalsComponent } from './dashboard/patient-profile/goals/goals.component';
import { AssessmentComponent } from './dashboard/patient-profile/assessment/assessment.component';
import { LeftchartComponent } from './dashboard/patient-profile/leftchart/leftchart.component';
import { ProfileheaderComponent } from './dashboard/patient-profile/profileheader/profileheader.component';
import { PatientoverviewComponent } from './dashboard/patient-profile/patientoverview/patientoverview.component';
import { PatientOverviewComponent } from './dashboard/patient-overview/patient-overview.component';
import { OverviewTableComponent } from './dashboard/patient-overview/overview-table/overview-table.component';
import { PatientGoalsComponent } from './dashboard/patient-goals/patient-goals.component';
import { GoalTrackerComponent } from './dashboard/patient-goals/goal-tracker/goal-tracker.component';
import { ReviewFormComponent } from './dashboard/review-form/review-form.component';
import { AdsFormComponent } from './dashboard/review-form/ads-form/ads-form.component';
import { AddRemarksComponent } from './dashboard/add-remarks/add-remarks.component';
import { AsesmnttActivityComponent } from './dashboard/asesmntt-activity/asesmntt-activity.component';
import { GenerateReportComponent } from './dashboard/generate-report/generate-report.component';
import { ProfileAssesementComponent } from './dashboard/profile-assesement/profile-assesement.component';
import { ProgressTrackerComponent } from './dashboard/progress-tracker/progress-tracker.component';
import { AssesmentToolComponent } from './dashboard/assesment-tool/assesment-tool.component';
import { SideNavbarComponent } from './dashboard/side-navbar/side-navbar.component';
import { MaterialModule } from './material.module';
import { PatientsNewComponent } from './dashboard/patients-new/patients-new.component';
import { VchatComponent } from './videochat/vchat/vchat.component';
import { SiteAnalyticsComponent } from './dashboard/analytics/site-analytics/site-analytics.component';
import { UserProfileComponent } from './dashboard/profile/user-profile/user-profile.component';
import { DndDirective } from './dnd.directive';
import { MaFormComponent } from './dashboard/patient-profile/ma-form/ma-form.component';
import { TicketsComponent } from './dashboard/tickets/tickets.component';
import { ClientBasicProfileComponent } from './dashboard/client-basic-profile/client-basic-profile.component';
import { AudioTestComponent } from './dashboard/audio-test/audio-test.component';
import { FileUploadsComponent } from './dashboard/patient-profile/file-uploads/file-uploads.component';
import { ProfileInfoComponent } from './dashboard/profile-info/profile-info.component';
import { ClientWalletComponent } from './dashboard/client-wallet/client-wallet.component';
import { TherapyPackageComponent } from './dashboard/patient-profile/therapy-package/therapy-package.component';
import { PatientLogComponent } from './dashboard/patient-log/patient-log.component';
import { SessionReportsComponent } from './dashboard/session-reports/session-reports.component';






@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomeComponent,
    //PatientComponent,
    //OverviewComponent,
    ScheduleComponent,
    PatientsListComponent,
    LoginComponent,
    ReceptionistHomeComponent,
    //PersonalDetailsComponent,
    EnquiryComponent,
    BillingComponent,
    NotificationComponent,
    ProfileComponent,
    //EventsComponent,
    //SymptomsDiagnosisComponent,
    //IndividualScheduleComponent,
    ViewerComponent,
    //StatisticsComponent,
    //StatisticsOverviewComponent,
    //StatisticsDemographyComponent,
    //StatisticsRevenueComponent,
    //StatisticsDisabilityComponent,
    //UsersComponent,
    //ServiceComponent,
    //InventoryComponent,
    
    //PurchaseOrdersComponent,
    //SalesComponent,
    //TransferLogComponent,
    //AssetsComponent,
    //HometherapyGoalsComponent,
    //HometherapyComponent,
    //PurchaseOrderComponent,
    //PurchaseHistoryComponent,
    ReportIssueComponent,
    //PatientFeedbackComponent,
    //FeedbacksComponent,
    //FormBuilderComponent,
    //FormSubmissionsComponent,
    //FormSubmissionComponent,
    //ChildHistoryComponent,
    //IndexComponent,
    //MedicalFamilyHistoryComponent,
    //LanguageComponent,
    //SocioFamilyComponent,
    //ChildRegistrationComponent,
    //ChildInformationComponent,
    //FamilyHistoryComponent,
    //BirthHistoryComponent,
    //MedicalHistoryComponent,
    //BehaviouralHistoryComponent,
    //EduHistoryComponent,
    //ChildDevInventoryComponent,
    ResourceComponent,
    //HomeprogramComponent,
    ChatWindowComponent,
    PublisherComponent,
    SubscriberComponent,
    //ResourceLibraryComponent,
    //HomeprogramInternalComponent,
    //SignupComponent,
    //SuperAdminComponent,
    BillComponent,
    //RecentActivitiesComponent,
    PatientsBranchComponent,
    PatientsReferredComponent,
    //TbcfComponent,
    //InfancyComponent,
    //AdditionalInfoComponent,
    //AnnexeComponent,
    //CdiResultComponent,
    JwPaginationComponent,
    //TagsComponent,
    //FormComponent,
    //AppointmentsComponent,
    AnalyticsComponent,
    //SettingsComponent,
    //IdeComponent,
    //GoalsActivitiesComponent,
    //SurveyComponent,
    //ChecklistComponent,
    //MrRecordComponent,
    ScheduleFlexComponent,
    GenerateBillComponent,
    //LessonPlanComponent,
    //SessionReportComponent,
    //BillHeadsComponent,
    //AssessmentsComponent,
    PatientProfileComponent,
    ProfileboxComponent,
    HeaderComponent,
    GoalsComponent,
    AssessmentComponent,
    LeftchartComponent,
    ProfileheaderComponent,
    PatientoverviewComponent,
    PatientOverviewComponent,
    OverviewTableComponent,
    PatientGoalsComponent,
    GoalTrackerComponent,
    ReviewFormComponent,
    AdsFormComponent,
    AddRemarksComponent,
    AsesmnttActivityComponent,
    GenerateReportComponent,
    ProfileAssesementComponent,
    ProgressTrackerComponent,
    AssesmentToolComponent,
    SideNavbarComponent,
    PatientsNewComponent,
    VchatComponent,
    SiteAnalyticsComponent,
    UserProfileComponent,
    DndDirective,
    MaFormComponent,
    TicketsComponent,
    ClientBasicProfileComponent,
    AudioTestComponent,
    FileUploadsComponent,
    ProfileInfoComponent,
    ClientWalletComponent,
    TherapyPackageComponent,
    PatientLogComponent,
    SessionReportsComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxUiLoaderModule,
    ImageViewerModule.forRoot(),
    AutocompleteLibModule,
    ProfileModule,
    
  ],
  exports:[
    ProfileboxComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
