// @ts-nocheck
import { Component, OnInit, Renderer2 } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

	data = [];
	customDate = 0;
	date:any;
	status = '';
	reschedule=[];
	therapists=[];
	selectedTherapist=0;
	rescheduleDate= "";
	freeSlots = [];
	rescheduleId:any;
	departments:any = {departments:[{id:0}]};
	regSlot=0;
	regConsultant=0;
	regDate:any;
	repeatType = 0;
	repeatCount = 2;
	selectedSlot = {"visits":[], "notes":[], "upcoming":[], "upcoming_months":[], "past":[], "past_months":[]};
	upcoming = [];
	cancelMode = 0;
	cancelUpcoming = 0;
	allow_edit = localStorage.getItem('allow_edit');
	groupSlots = [];
	consultations = [];
	role = localStorage.getItem('role_id');
	runSchedule:any;
	cachedSlotCount = 0;
	cancelView=0;
	nextReg="";
	selectedDept = 0;

	idLocal:any = ["", "Aadhaar card", "Voters ID", "PAN Card", "Driving license","Passport"];
  	idAe:any = ["", "Emirates ID", "Passport"];
  	ids:any = [];

	branch:any = {id:0, name:""};

	/* for viewSlot */

	selectedMonth = "";
	cancelSlotId = "";
	cancelFrom = '0';
	openSlot = {"slot_id":"", "therapist_id":""}; //slot that's currently open for viewing.
	other_appointments = [];
	other_months = [];
	appointmentsMode = 1;

	selectedVisit = {"upcoming":[], "upcoming_months":[], "past":[], "past_months":[]};


  	constructor(private router:Router, private dataService: DataService) { 
  		this.router.routeReuseStrategy.shouldReuseRoute = function() {
	        return false;
	    };
  	}

	ngOnInit() {
		var self = this;
		if(localStorage.getItem('scheduleDate') === null ) {
			this.date = 0;
		} else {
			//this.date = localStorage.getItem('scheduleDate');
		}

		if(localStorage.getItem("branch") !== null){
			this.branch = JSON.parse( localStorage.getItem("branch"));
			
		}
		
		this.getTherapists();
		this.loadDepartments();
		this.loadDate(this.date);
		this.loadOrg();

		this.runSchedule = window.setInterval(() => this.getAppointments(), 60000);
		


		//datepicker
		
		$( function() {
			(<any>$("#reschedule-date" )).datepicker({
		    	dateFormat: "dd-mm-yy",
		    	beforeShow: function() {
			        setTimeout(function(){
			            $('.ui-datepicker').css('z-index', 99999999999999);
			        }, 0);
			    },
			    onSelect: function (dateText, inst) {
					this.focus();
			    }
			});
		});

		$( function() {
			(<any>$("#schedule-date" )).datepicker({
		    	dateFormat: "dd-mm-yy",
		    	onSelect: function (dateText, inst) {
					this.focus();

			    }
			});

			
		});

		
	}

	ngOnDestroy() {
	  if (this.runSchedule) {
	    clearInterval(this.runSchedule);
	  }
	}

	loadOrg(){
    
		this.dataService.getOrg(this.branch.id).subscribe( (data: any = {org_id:0, ac:0} ) =>{
			
			let aeOrgs = [4,6,7,8];
			if(aeOrgs.includes(data.org_id) ){
				this.ids = this.idAe;
			} else {
				this.ids = this.idLocal;
			}
			
		});
	}

	loadDate(date) {
		
		this.date = date;
		
		var dept = $('#dropdownMenuButton').attr('data-dept');

		console.log(dept);
		
		this.dataService.getDate(date, dept, this.customDate, this.cancelView, this.branch.id).subscribe( (data: any["selected"] )=> {
			this.data = data;
			this.date = data.selected;
			

			this.getAppointments();
			
			
		} );
		
	}

	loadDepartments() {
		this.dataService.getDepts().subscribe( (data: any["selected"] )=> {
			this.departments = data;
			
			console.log(this.departments);
			
		} );
	}
	

	openRegistration(date){
		$('#inputRegNo').val( $('#inputNameApp').val() );
		this.regDate = date;
			
		this.dataService.getNextPatientId().subscribe( (data: any[] ) =>{
	          //@ts-ignore
	          if (data.status == 0) {
	            //@ts-ignore
	            this.nextReg = data.reg_no;
	          }
	          
	      });

		
	}

	openAppointment(consultant, slot, selected){
		this.selectedTherapist = consultant;	  	
		this.regConsultant = consultant;
		this.regSlot = slot;
		$('#inputConsultant').val(consultant);
		$('#inputSlotId').val(slot);
		$('#inputDate').val(selected);
		
		
		
	}

	changeDept(dept, dept_id) {
		$('#dropdownMenuButton').text(dept);
		$('#dropdownMenuButton').attr('data-dept', dept_id);
		this.selectedDept = dept_id;
		this.loadDate(this.date);

		
		
	}

	cancelAppointment(type, appointment_id) {
		var cnf = confirm("Are you sure you want to cancel this appointment?");

		if(cnf) {
			this.dataService.cancelAppointment(type, appointment_id).subscribe( (res:any) => {
				if(res.status == 0) {
		        	alert(res.msg);
		        	this.getAppointments();
		        
		      	} else {
		        	alert(res.msg);
		      	}
			} );
		}


	}

	loadReschedule(appointment_id) {

		$('#rescheduleModal').modal('show');

		this.rescheduleId = appointment_id;
		this.dataService.loadReschedule(appointment_id).subscribe( (data: any[] )=> {
			this.reschedule = data;
			
		} );
	}

	getTherapists(){
		this.dataService.getTherapists(this.branch.id).subscribe( (data: any[] )=> {
			this.therapists = data;
			
		} );	
	}

	setRescheduleDate(val) {
		
		/*if (moment(val, 'DD-MM-YYYY',true).isValid()) {
			
		}*/
		this.rescheduleDate = val;
		this.getSlots(); 

		
	}

	changeTherapist(val){
		this.selectedTherapist = val;
		this.getSlots();
	}

	getSlots() {
		this.dataService.getSlots(this.rescheduleDate, this.selectedTherapist).subscribe( (res: any["status"] )=> {
			if (res.status==0) {
				this.freeSlots = res.slots;
			}
			
		} );	
	}

	rescheduleSlot() {
		var data = {
			"date":this.rescheduleDate,
			"therapist":this.selectedTherapist,
			"appointment_id":this.rescheduleId,
			"slot":"",
		};

		var selectedSlot = $('ul.rescheduleSlotSelector li.active').attr('data-slot');
		data.slot = selectedSlot;

		this.dataService.postReschedule(data).subscribe( (res:any) => {

			if(res.status == 0) {
		        alert(res.msg);
		        $('#rescheduleModal').modal('hide');
		        $('#reschedule-date').val('');
		        this.freeSlots = [];
		        //this.loadDate(this.date);
		        this.getAppointments();
		        this.viewVisit(this.rescheduleId);
		        
	      	} else {
	        	alert(res.msg);
	      	}

		} );

	
	}

	replace(slot_id) {
		$('#inputReplace').val(slot_id);
		$('#addAppointment').modal('show');
	}

	markHoliday(date) {
		var data = {"date":date};
		var cnf = confirm("Are you sure you want to mark this date as holiday?");

		if(cnf) {
			this.dataService.postMarkHoliday(data).subscribe((res:any) => {
			
				if(res.status == 0) {
			        alert(res.msg);
			        this.loadDate(this.date);
			        
		      	} else {
		        	alert(res.msg);
		      	}
			});
		}


		

	}

	markLeave(consultant) {
		var data = {"consultant":consultant, "date":this.date};
		var cnf = confirm("Are you sure you want to mark leave for this consultant?");

		if(cnf) {
			this.dataService.postMarkLeave(data).subscribe((res:any) => {
				if(res.status == 0) {
			        alert(res.msg);
			        this.loadDate(this.date);
			        
		      	} else {
		        	alert(res.msg);
		      	}
			});
		}

		
	}

	submitRegistrationSchedule(data) {
	    data.dob = $('#inputDOB1').val();
	    data.patient_since = $('#inputSince1').val();
	    data.oldregno = $('#inputRegNo').val();
	    $('#btn-reg').attr('disabled', 'true');
	    $('#btn-reg').text('Submitting');

	    var treatments = [];

	    $('ul.treatment-selector li').each(function(){
	      if( $(this).hasClass('active') ) {
	        treatments.push( $(this).text() );
	      }
	    })

	    data.treatments = treatments;
	    data.regSlot = this.regSlot;
	    data.regDate = this.regDate;
	    data.regConsultant = this.regConsultant;
	    data.is_appointment = 1;
		data.branch = this.branch.id;
	    

	    
	    
	    this.dataService.postRegistration(data).subscribe( (res:any) => {
	    	$('#btn-reg').removeAttr('disabled');
	    	$('#btn-reg').text('Submit');
	      
	      if(res.status == 0) {
	        alert(res.msg);
	        $('#addPatientSchedule').modal('hide');
	        //@ts-ignore
	        document.getElementById("formRegs").reset(); 
	        $('.treatment-selector li').each(function(){
	        	$(this).removeClass('active');
	        });
	        //this.loadDate(this.date);
	        this.getAppointments();
	        
	      } else {
	        alert(res.msg);
	      }

	    }); 

	    

	    
	}

	submitAppointment() {
		var data = {
	      "consultant_id": $('#inputConsultant').val(),
	      "patient_id": $('#inputPatientId').val(),
	      "slot_id": $('#inputSlotId').val(),
	      "visit_purpose": $('#inputPurpose').val(),
	      "date":$('#inputDate').val(),
	      "repeat_type": this.repeatType,
	      "repeat_count": this.repeatCount,
	    };



	    if(data.patient_id == "" || data.patient_id == 0 ) {
	      alert('Select patient from suggestions');
	    } else {

	    	
	      $('#btn-appointment').attr('disabled', 'true');
	      $('#btn-appointment').text('Submitting');
	      
	      this.dataService.postAppointment(data).subscribe( (res:any) => {
	      	$('#btn-appointment').removeAttr('disabled');
	      	$('#btn-appointment').text('Add Appointment');
	      	this.repeatType = 0;
	        if(res.status == 0) {
	          alert(res.msg);
	          $('#addAppointment').modal('hide');
	          //@ts-ignore
	          document.getElementById("formApp").reset(); 
	          
	          //this.loadDate(this.date);
	          this.getAppointments();
	          
	        } else {
	          alert(res.msg);
	        }
	      });
	     
	    }

	    


	}

	deleteNote(note_id){
		
		if (localStorage.getItem('role_id') == '5' ) {
			var cnf = confirm("To delete this note, press OK below.");

			if(cnf) {
				var data = {"note_id":note_id};
				this.dataService.postDeleteSlotNote(data).subscribe((res:any) => {
				
					if(res.status == 0) {
				        //alert(res.msg);
				        //this.loadDate(this.date);
				        this.getAppointments();
				        this.viewSlot(this.openSlot.slot_id, this.openSlot.therapist_id,0, 1, 0);
				        
			      	} else {
			        	alert(res.msg);
			      	}
				});
			}
		}

		




	}

	submitSlotNote() {
		
		var data = {
			"note" : $('#inputSlotNote').val(),
			"slot_id" : $('#inputSlotId').val(),
			"date":$('#inputDate').val(),
			"consultant_id": $('#inputConsultant').val(),
		}

		$('#btn-slotnote').attr('disabled', 'true');
	    $('#btn-slotnote').text('Submitting');

		this.dataService.postSlotNote(data).subscribe( (res:any) => {
			$('#btn-slotnote').removeAttr('disabled');
	      	$('#btn-slotnote').text('Add Note');

	      	if(res.status == 0) {
	          alert(res.msg);
	          $('#addAppointment').modal('hide');
	          //@ts-ignore
	          document.getElementById("formSlotNote").reset(); 
	          
	          //this.loadDate(this.date);
	          this.getAppointments();
	          
	        } else {
	          alert(res.msg);
	        }

		});
	}

	setRepeatType(val) {
		this.repeatType = val;
		
	}

	setRepeatCount(val){
		this.repeatCount = val;

	}

	

	switchDate(val) {

		if (val !== null) {
			this.customDate = val;
			this.date = val;
			this.loadDate(this.date);
		}
		
		
	}

	showDetails(id, docid, count){
		if (docid != 0) {
			this.selectedTherapist = docid;
		}

		//this.upcoming = [];

		this.dataService.getVisitDetails(id).subscribe( (data: any[] )=> {
				//@ts-ignore
				this.selectedSlot = data.data;

				
			} );
		if (count==0) {
			count = this.cachedSlotCount;
		} else {
			this.cachedSlotCount = count;
		}

		if (count == 1) {
			//get upcoming appointments;
			this.groupSlots = [];
			

			this.dataService.getUpcoming(id).subscribe( (data: any[] )=> {
				//@ts-ignore
				this.upcoming = data.data;
				
			} );



		} else{
			this.upcoming = [];
			//get group appointments;
			this.dataService.getGroupAppointments(id).subscribe((data: any[] )=>{
				//@ts-ignore
				this.groupSlots = data;
				
				
			});

		}

		
		
		
	}



	viewSlot(slot_id, therapist_id, selected, show_group, appointment_id){

		

		if (therapist_id != 0) {
			this.selectedTherapist = therapist_id;
		}



		this.openSlot.slot_id = slot_id;
		this.openSlot.therapist_id = therapist_id;

		if (show_group == 1) {

			this.dataService.getViewSlot(slot_id, therapist_id, this.date, selected).subscribe( (data: any[] )=> {
				//@ts-ignore
				this.selectedSlot = data;
				
				
				
			} );
			$('#slotModal').modal('show');

		}else {


			
			this.viewVisit(appointment_id);

			
		}

		


	}

	viewVisit(appointment_id){

		this.dataService.getViewVisit(appointment_id).subscribe( (data: any[] )=> {
				//@ts-ignore
				this.selectedVisit = data;
				//@ts-ignore
				this.other_appointments = data.upcoming;
				//@ts-ignore
				this.other_months = data.upcoming_months;
				//@ts-ignore
				this.selectedMonth = data.upcoming_months[0];
				this.appointmentsMode = 1;
				
				
			} );

		$('#detailsModal').modal('show');

	}

	setCancelMode(val){
		this.cancelMode = val;
		
	}
	setGroupCancelMode(index, val) {
		//@ts-ignore
		this.groupSlots.group[index].cancelMode = val;
	}

	setCancelUpcoming(){
		if (this.cancelUpcoming == 0 ) {
			this.cancelUpcoming =1;
		} else {
			this.cancelUpcoming = 0;
		}


	}
	setCancellation(val, from) {
		this.cancelSlotId = val;
		this.cancelFrom = from;

	}

	confirmCancel(){

		
		var data = {
			//@ts-ignore
			"appointment_id": this.cancelSlotId,
			"cancellation_mode": this.cancelMode,
			"cancel_upcoming":this.cancelUpcoming,
		}

		

		
		$('.btn-cancel-confirm').attr('disabled', 'true');
		$('.btn-cancel-confirm').text('loading.');

		
		
		this.dataService.postCancelAppointment(data).subscribe( (res:any) => {

			if(res.status == 0) {
		        alert(res.msg);
		        //clear data
		        this.cancelMode = 0;
		        this.cancelUpcoming = 0;
		        //@ts-ignore
		        this.selectedSlot.is_cancelled = 1;
		        $('.btn-cancel-confirm').removeAttr('disabled');
				$('.btn-cancel-confirm').text('Confirm Cancel');
				
				if(this.cancelFrom == '1') {
					//@ts-ignore
					this.viewVisit(this.cancelSlotId);	

				}else{
					//@ts-ignore
					//console.log(this.selectedSlot.slot_id + "-" + this.selectedSlot.show_group + " - " + this.selectedSlot.appointment_id )
					//this.viewSlot(this.selectedSlot.slot_id, 0,0, this.selectedSlot.show_group, this.selectedSlot.appointment_id);
				}

				//this.loadDate(this.date);
				this.getAppointments();

				$('#cancelModal').modal('hide');
		        
	      	} else {
	        	alert(res.msg);
	      	}

		});

		
		
		
		
	}

	cancelFromSlot(visit_id, mode){
		var cnf = confirm("Are you sure you want to cancel this appointment?");

		if (cnf) {
			var data = {
				//@ts-ignore
				"appointment_id": visit_id,
				"cancellation_mode": mode,
				"cancel_upcoming":0,
			}

			this.dataService.postCancelAppointment(data).subscribe( (res:any) => {

			if(res.status == 0) {
		        alert(res.msg);
		        
		        //this.loadDate(this.date);
		        this.getAppointments();
		        this.viewSlot(this.openSlot.slot_id, this.openSlot.therapist_id,0, 1, 0);
				
		        
	      	} else {
	        	alert(res.msg);
	      	}

		});
		}

	}

	confirmGroupCancel(index){
		var data = {
			//@ts-ignore
			"appointment_id": this.groupSlots.group[index].id,
			//@ts-ignore
			"cancellation_mode": this.groupSlots.group[index].cancelMode,
			"cancel_upcoming": this.cancelUpcoming,
			"clear_field":1,
		}

		

		
		$('.btn-cancel-confirm').attr('disabled', 'true');
		$('.btn-cancel-confirm').text('loading.');
		
		
		
		this.dataService.postCancelAppointment(data).subscribe( (res:any) => {

			if(res.status == 0) {
		        alert(res.msg);
		        //this.loadDate(this.date);
		        this.getAppointments();

		        $('.btn-cancel-confirm').removeAttr('disabled');
				$('.btn-cancel-confirm').text('Confirm Cancel');
				//@ts-ignore
				this.showDetails(this.selectedSlot.id,0,0);
		        
	      	} else {
	        	alert(res.msg);
	      	}

		});
		

		
		
		
		
	}

	markPresent(id) {
    	

    	

	    var data = {"appointment_id":id};
	    this.dataService.postMarkPresent(data).subscribe( (res:any) => {
	        if(res.status == 0) {
	        	//@ts-ignore
	            this.viewVisit(id);
	       		//this.loadDate(this.date);     
	       		this.getAppointments();
	          } else {
	            alert(res.msg);
	          }
	      } );

	    

	}

	markDischarge(id,val) {
    	
	    var data = {"appointment_id":id, "value":val};
	    if (val == 3) {
	    	var msg = "Are you sure you want to discharge this patient?"
	    } else if(val == 4) {
	    	var msg = "Are you sure you want to mark this patient discontinued?"
	    }
	    var cnf = confirm(msg);

	    if (cnf) {
	    	this.dataService.postMarkDischarge(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            this.viewVisit(id);
		       		//this.loadDate(this.date);     
		       		this.getAppointments();
		          } else {
		            alert(res.msg);
		          }
		    });
	    }

	    

	}


	  clearSlot() {

	  	var cnf = confirm("Are you sure you want to clear this slot?");

	  	if (cnf) {
	  		//@ts-ignore
	  		var data = {"appointment_id": this.groupSlots.group[0].id };
		  	this.dataService.postClearSlot(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            this.showDetails(this.selectedSlot.id,0,0);
		       		//this.loadDate(this.date);     
		       		this.getAppointments();
		       		$('#detailsModal').modal('hide');
		          } else {
		            alert(res.msg);
		          }
		      } );
	  	}


	  	


	  }

	  loadConsultations(){

		//this.selectedTherapist = id;	  	
	  	this.dataService.getConsultations(this.date, this.selectedDept).subscribe( (data: any[] )=> {
			this.consultations = data;
			
			
		} );
		
	  }

	  submitConsultation(){

	  	var data = {
	  		"patient_id": $('#inputConsultation').attr('data-patient'),
	  		"date":this.date,
	  		"consultant":this.selectedTherapist,
	  		"type": $('#inputConsultation').attr('data-type'),
	  	};

	  	this.dataService.postConsultation(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            alert(res.msg);
		       		this.loadDate(this.date);     

		       		this.loadConsultations();
		       		$('#inputConsultation').val('');
		       		$('#inputConsultation').attr('data-patient', 0);
		       		$('#addAppointment').modal('hide');
		          } else {
		            alert(res.msg);
		          }
		      } );

	  }

	  submitConsultation2(){

	  	var data = {
	  		"patient_id": $('#inputConsultation2').attr('data-patient'),
	  		"date":this.date,
	  		//@ts-ignore
	  		"consultant":this.data.cArray[0].id,
	  		"type": $('#inputConsultation2').attr('data-type'),
	  	};

	  	this.dataService.postConsultation(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            this.loadDate(this.date);     
		       		this.loadConsultations();
		       		$('#inputConsultation2').val('');
		       		$('#inputConsultation2').attr('data-patient', 0);
		       		
		          } else {
		            alert(res.msg);
		          }
		      } );
	  	
	  }

	  cancelConsultation(id){
	  	var cnf = confirm("Are you sure you want to cancel this consultation?");

	  	if(cnf){

	  		var data =  {"id":id};

	  		this.dataService.postCancelConsultation(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            
		       		this.loadDate(this.date);     
		       		this.loadConsultations();
		       		
		          } else {
		            alert(res.msg);
		          }
		      } );
	  	}

	  }

	  print(){
	  	window.print();
	  }

	  setCancelView(val){
	  	this.cancelView = val;
	  	//this.loadDate(this.date);
	  	this.getAppointments();
	  }

	  selectRegNo(){
	      $('#inputRegNo').val(this.nextReg);
	  }

	  selectMonth(month){
	  	this.selectedMonth = month;
	  	$('#collapseExample').collapse('show');
	  }
	  
	  switchAppointmentsMode(mode){
	  		this.appointmentsMode = mode;
	  		if (mode==1) {
	  			this.other_appointments = this.selectedVisit.upcoming;
	  			this.other_months = this.selectedVisit.upcoming_months;
	  			
	  		}else if(mode==2){
	  			this.other_appointments = this.selectedVisit.past;
	  			this.other_months = this.selectedVisit.past_months;

	  		}
	  }

	  getAppointments() {
	  	
	  	var date = this.date;
	  	var cancelView = this.cancelView;
	  	var context = this;
	  	//@ts-ignore
	  	this.data.cArray.forEach(function(item, index){
	  		
	  		context.dataService.getAppointments(date, item.id, cancelView).subscribe( (data: any["selected"] )=> {
				
				//console.log(data);
				if (data.status == 0) {
					item.beforeBreak = data.docSlots;
				}
				
			});
	  		
	  	});
	  	

	  }

	  
	
	

}
